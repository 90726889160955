export const clients = [
  {
    title: "Tenacity Client",
    subtitle: "Blatant",
    description: "Honestly, it's pretty good lmao.",
    image: "https://i.ytimg.com/vi/HC7hniK5g-c/maxresdefault.jpg",
    link: "https://tenacity.dev",
  },
  {
    title: "Azura Client",
    subtitle: "Blatant",
    description:
      "A good free client; I got it with an invite code; I didn't apply for it.",
    image: "https://i.ytimg.com/vi/F7Ok7P9TlX0/maxresdefault.jpg",
    link: "https://azura.best",
  },
  {
    title: "Juul Client",
    subtitle: "Ghost/Closet",
    description: "Sometimes I use it.",
    image: "https://i.ytimg.com/vi/7tcibTd9Pqc/maxresdefault.jpg",
    link: "https://juul.lol",
  },
  {
    title: "Novoline.wtf",
    subtitle: "Blatant",
    description:
      "got as a joke fr",
    image: "https://i.ytimg.com/vi/RvqkLHdish8/hqdefault.jpg",
    link: "https://novoline.wtf",
  },
  {
    title: "Novoline Intent",
    subtitle: "Blatant",
    description:
      "got as a joke as well",
    image: "https://i.ytimg.com/vi/RvqkLHdish8/hqdefault.jpg",
    link: "https://intent.store",
  },
  {
    title: "Future",
    subtitle: "Anarchy",
    description:
      "good anarchy client",
    image: "https://th.bing.com/th/id/OIP.hgE-EuOQLTAbsNLmnzFz4AHaEK?pid=ImgDet&rs=1",
    link: "https://futureclient.net",
  },
  {
    title: "Future",
    subtitle: "Anarchy",
    description:
      "y not ig?",
    image: "https://i.ytimg.com/vi/NNOW-WZDteA/maxresdefault.jpg",
    link: "https://intent.store",
  },
];
